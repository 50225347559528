import React, { useState, useContext, useEffect } from 'react';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, ConfigProvider } from 'antd';
import { signUp } from '../../utils/api';
import './style.css';
import { LanguageContext } from '../../utils/LanguageContext';

const SignUpForm = () => {
  const [formState, setFormState] = useState({
    username: '',
    email: '',
  });
  const [error, setError] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const { language, changeLanguage, translations } =
    useContext(LanguageContext);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleFormSubmit = async (formValues) => {
    try {
      console.log(formValues);
      const response = await signUp(formValues);
      if (!response.ok) {
        setError(translations[language].registration_error_message);
      }
      const data = await response.json();
      console.log('account created', data);
      setSignupSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#3BC14A',
          },
        }}
      >
        {!signupSuccess ? (
          <Form
            className="login-form"
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={formState}
          >
            <Form.Item
              label={translations[language].name_form}
              name="username"
              rules={[
                {
                  required: true,
                  message: translations[language].name_error_message,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={translations[language].name_placeholder}
              />
            </Form.Item>
            <Form.Item
              label={translations[language].email_form}
              name="email"
              rules={[
                {
                  required: true,
                  message: translations[language].email_error_message,
                  type: 'email',
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                type="email"
                placeholder={translations[language].email_placeholder}
              />
            </Form.Item>
            <Form.Item className="login-form-item">
              <Button
                type="primary"
                className="login-form-button"
                htmlType="submit"
              >
                {translations[language].register_button}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div>
            <p className="p-tag">
              {translations[language].registration_sucess_message}
            </p>
          </div>
        )}
      </ConfigProvider>
      {error && <div>{error}</div>}
    </div>
  );
};

export default SignUpForm;
