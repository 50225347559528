import React, { useContext, useState, useEffect } from 'react';
import { Menu, Row, Col, ConfigProvider, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../utils/LanguageContext';

import 'antd/dist/antd';
import './style.css';

const Navbar = () => {
  const { language, changeLanguage, translations } =
    useContext(LanguageContext);
  const [isEnglish, setIsEnglish] = useState(language === 'en');

  const handleLanguageToggle = (checked) => {
    if (checked) {
      changeLanguage('en');
    } else {
      changeLanguage('es');
    }
    setIsEnglish(checked);
  };

  return (
    <nav className="navbar">
      <Row align="middle" justify="space-between">
        <Col xs={24} sm={8} md={6} lg={6} className="navbar-title">
          <h1 className="title">
            <Link to="/" className="home-link">
              Crecer
            </Link>
          </h1>
        </Col>
        <Col xs={24} sm={16} md={18} lg={18} className="navbar-menu">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#3BC14A',
                colorBgBase: '#4D5057',
                colorTextBase: '#3BC14A',
                lineWidth: -1,
              },
            }}
          >
            <Menu
              mode="horizontal"
              className="custom-menu"
              overflowedIndicator={null}
            >
              <Menu.Item key="about" className="menu-item-custom">
                <Link to="/about" className="menu-link">
                  {translations[language].about}
                </Link>
              </Menu.Item>
              <Menu.Item key="contact" className="menu-item-custom">
                <Link to="/contact" className="menu-link">
                  {translations[language].contact}
                </Link>
              </Menu.Item>
              <Menu.Item
                key="language"
                className="menu-item-custom language-switch"
              >
                <Switch
                  checked={isEnglish}
                  onChange={handleLanguageToggle}
                  checkedChildren={
                    <span style={{ color: '#41c745' }}>Español</span>
                  }
                  unCheckedChildren={
                    <span style={{ color: '#41c745' }}>English</span>
                  }
                  className="custom-language-switch"
                  style={{ backgroundColor: 'white' }}
                />
              </Menu.Item>
            </Menu>
          </ConfigProvider>
        </Col>
      </Row>
    </nav>
  );
};

export default Navbar;
