import React, { useContext } from 'react';
import { LanguageContext } from '../../utils/LanguageContext';

import './style.css';

const Footer = () => {
  const { language, changeLanguage, translations } =
    useContext(LanguageContext);

  return (
    <footer className="app-footer">
      <div className="container">
        <span>{translations[language].footer}</span>
      </div>
    </footer>
  );
};

export default Footer;
