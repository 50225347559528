import React, { useContext } from 'react';
import { LanguageContext } from '../../utils/LanguageContext';

import './style.css';

const About = () => {
  const { language, changeLanguage, translations } =
    useContext(LanguageContext);

  return (
    <div className="container">
      <h2 className="page-title">{translations[language].about}</h2>
      <div className="content-container">
        <div className="text-content">{translations[language].about_copy}</div>
      </div>
    </div>
  );
};

export default About;
