// prettier-ignore
const translations = {
  en: {
    about: 'About',
    contact: 'Contact Us',
    footer: '2024 Crecer. All rights reserved.',
    homepage_title: 'Plan for a Relaxing Retirement',
    signup_button: 'Register for more information',
    register_button: 'Register',
    name_form: 'Name',
    name_placeholder: 'Enter your name',
    name_error_message: 'Please enter your name',
    email_form: 'Email',
    email_placeholder: 'Enter your email address',
    email_error_message: 'Please enter your email address',
    registration_success_message: `Success! We we'll be in touch!`,
    registration_error_message: 'Account was not created. Please verify your information and try again',
    home_copy: (
      <>
        <p>
          You can't work forever. You deserve the chance to relax and enjoy
          life. We can help you plan for a stress free retirement. Let your money
          grow while you're working.
        </p>
        <p>
          <span className="highlighted-text">How does Crecer work?</span> We create your personalized retirement account and manage the investments. You can
          choose how much you want to contribute to your account each month.
        </p>
        <p>
          <span className="highlighted-text">Why use Crecer?</span> Investing your money can be time consuming and stressful. Instead of spending hours per week trying to manage your account, you can use our intelligent system that automatically invests your money with
          strategies based on your age and risk tolerance.
        </p>
        <p>It's time to start!</p>
      </>
    ),
    contact_copy: (
      <>
        If you have any questions, you can send us an email at <a href="mailto:support@crecer.app">support@crecer.app</a>
      </>
    ),
    about_copy: `Our mission at Crecer is to help people from all levels of society manage their investments and plan for retirement. We're passionate about constructing investment portfolios and leveraging technology to provide equal access to the financial markets for all.`,
  },
  es: {
    about: 'Sobre Nosotros',
    contact: 'Cont\u00E1ctenos',
    footer: '2024 Crecer. Reservados todos los derechos.',
    homepage_title: 'Planea para una Jubilaci\u00F3n Tranquila',
    signup_button: 'Reg\u00EDstrate para mas informaci\u00F3n',
    register_button: 'Reg\u00EDstrate',
    name_form: 'Nombre',
    name_placeholder: 'Ingresa tu nombre',
    name_error_message: 'Por favor ingresa tu nombre',
    email_form: 'Correo',
    email_placeholder: 'Ingresa tu correo',
    email_error_message: 'Por favor ingresa tu correo',
    registration_success_message: 'Exito! Estaremos en contacto!',
    registration_error_message: 'No se pudo crear una cuenta. Por favor verifique tu información y vuelve a intentarlo.',
    home_copy: (
      <>
        <p>
          No puedes trabajar para siempre. Te mereces la oportunidad de
          relajarte y disfrutar la vida. Nosotros podemos ayudarte a planear
          para una jubilación tranquila. Deja que tu dinero crezca mientras
          estás trabajando.
        </p>
        <p>
          <span className="highlighted-text">¿Cómo funciona Crecer?</span> Nosotros creamos tu cuenta de retiro personalizada y manejamos las inversiones. Tú
          puedes elegir cuánto quieres poner en tu cuenta cada mes.
        </p>
        <p>
          <span className="highlighted-text">¿Por qué usar Crecer?</span> Invertir tu dinero puede llevar mucho tiempo y ser estresante. En lugar de pasar horas por semana tratando de administrar tu cuenta, puedes utilizar nuestro sistema inteligente que invierte automáticamente tu dinero con estrategias basadas en tu edad y tolerancia al riesgo.
        </p>
        <p>¡Ya es hora de empezar!</p>
      </>
    ),
    contact_copy: (
      <>
        Si tienes alguna pregunta puedes mandarnos un correo electrónico a <a href="mailto:soporte@crecer.app">soporte@crecer.app</a>
      </>
    ),
    about_copy: 'Nuestra misión en Crecer es ayudar a personas de todos los niveles de la sociedad a administrar sus inversiones y planificar su jubilación. Nos apasiona crear carteras de inversión y aprovechar la tecnología para brindar acceso igualitario a los mercados financieros para todos.',
  },
};

export default translations;
