import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../utils/LanguageContext';
import { useNavigate, useLocation } from 'react-router-dom';
import SignUpForm from '../../components/SignupForm';
import { Button } from 'antd';
import './style.css';

const HomePage = () => {
  const [isSignup, setIsSignup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { language, changeLanguage, translations } =
    useContext(LanguageContext);

  // Sync isSignup with the URL path
  useEffect(() => {
    if (location.pathname === '/signup') {
      setIsSignup(true);
    } else {
      setIsSignup(false);
    }
  }, [location.pathname]);

  const handleClick = () => {
    // setIsSignup(true);
    navigate('/signup');
  };

  return (
    <main>
      <h2 className="page-title">{translations[language].homepage_title}</h2>
      {isSignup ? (
        <SignUpForm />
      ) : (
        <div className="content-container">
          <div className="text-content">
            {translations[language].home_copy}
            <Button
              type="primary"
              className="create-account-button"
              onClick={handleClick}
            >
              {translations[language].signup_button}
            </Button>
          </div>
          {/* <div className="image-container">
            <img
              src="/images/Crecer_homepage_pic.png"
              className="homepage-image"
            />
          </div> */}
        </div>
      )}
    </main>
  );
};

export default HomePage;
